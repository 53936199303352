<template>
  <div class="question-box">
    <div class="tip-content">
      <h4 class="usage-title">Usage Restrictions Notice</h4>
      <p>Welcome to our platform! To ensure your user experience and the safety of others, we have established the following usage restrictions. Please adhere to them:</p>
      <ul class="usage-limit">
        <li>Copyright Protection: Ensure that the content you publish does not infringe upon the copyrights of others. Compliance with intellectual property laws is your responsibility.</li>
        <li>Privacy Protection: Do not disclose others’ personal information, including phone numbers, addresses, etc. Respecting the privacy of others is our fundamental principle.</li>
        <li>Legal Compliance: Do not engage in any illegal activities on our platform. We adhere to local laws and regulations, and we expect you to do the same.</li>
        <li>Freedom of Speech: While we respect freedom of speech, please ensure that your speech does not cause malicious harm or mislead others.</li>
        <li>Fair Usage: Please use our services reasonably and avoid overloading servers or engaging in any form of abuse.</li>
      </ul>
      <p class="questions-list">
        If the following prompt icon appears when sending a message, the general reasons are as follows:
      </p>
      <ul class="question-list-li">
        <li>
          <i class="el-icon-loading" />
          <span class="icon-desc">Message is being sent</span>
        </li>
        <li>
          <i class="el-icon-warning" />
          <span class="icon-desc">Failed to send</span>
        </li>
        <li>
          <i class="el-icon-s-goods" />
          <span class="icon-desc">Balance not enough</span>
        </li>
        <li>
          <i class="el-icon-remove" />
          <span class="icon-desc">Forbidden to send</span>
        </li>
        <li>
          <i class="el-icon-remove-outline"></i>
          <span class="icon-desc">The number exceeds the limit of sending messages in one day</span>
        </li>
        <li>
          <i class="el-icon-time" />
          <span class="icon-desc">Waiting period for sending message</span>
        </li>
        <li>
          <i class="el-icon-odometer"></i>
          <span class="icon-desc">Sending messages exceeds limit</span>
        </li>
        <li>
          <i class="el-icon-s-opportunity"></i>
          <span class="icon-desc">Unfamiliar news to be confirmed</span>
        </li>
        <li>
          <i class="el-icon-link"></i>
          <span class="icon-desc">The number has been unbound</span>
        </li>
      </ul>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'question',
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.question-box {
  .tip-content {
    font-size: 12px;
    text-align: left;
    .usage-title {
      margin: 6px;
      text-align: center;
    }
    .usage-limit {
      padding-left: 14px;
    }
    .question-list-li {
      font-size: 18px;
      padding-left: 10px;
      li {
        display: flex;
        padding: 4px 0;
        align-items: center;
      }
      .icon-desc {
        font-size: 12px;
        margin-left: 10px;
      }
      .el-icon-warning {
        color: rgb(236, 52, 52);
      }
      .el-icon-s-goods, .el-icon-s-opportunity {
        color: rgb(255, 166, 32);
      }
      .el-icon-remove, .el-icon-remove-outline {
        color: rgb(251, 82, 82);
      }
      .el-icon-timer {
        color: rgb(39, 124, 0);
      }
      .el-icon-odometer, .el-icon-link {
        color: rgb(255, 21, 40);
      }
    }
  }
}
</style>
